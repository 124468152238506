import React, { useState } from "react";
import axios from "axios";
import { headers_image_upload } from "Helpers/FetchApiMethod";
import {Card,Container,Row,Col,Form,Button,CardBody,} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { swalfirefunctionality } from "services/alertServices";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { vacancyAddSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { apiURL } from "components/URLs/URL";

function AddVacancy() {
  const navigate = useNavigate();
  const [firmImageName, setFirmImageName] = useState(undefined);
  async function imageUploadResult(event) {
    const fileValue = event.target.files[0];
    if (fileValue.size <= 1024 * 1024) {
      let imageFileName = Date.now() + "_" + fileValue.name;
      let newIpFormData = new FormData();
      newIpFormData.append("folderName", "images");
      newIpFormData.append("file", fileValue, fileValue.name);
      newIpFormData.append(
        "imageName",
        imageFileName.replaceAll(" ", "").split(".")[0]
      );
      const uploadImageRespo = await axios.post(
        `${apiURL}image_upload`,
        newIpFormData,
        { headers: headers_image_upload }
      );
      try {
        if (uploadImageRespo.data != undefined) {
          if (uploadImageRespo.data.status == true) {
            console.log(
              uploadImageRespo.data.data,
              "uploadImageRespo.data.data"
            );
            setFirmImageName(uploadImageRespo.data.data);
            swalfirefunctionality(
              "Good Job",
              `${uploadImageRespo.data.message}`,
              "success"
            );
          } else {
            swalfirefunctionality("error", "Your image not uploaded", "error");
          }
        } else {
        }
      } catch (error) {
        swalfirefunctionality("error", `${error}`, "error");
      }
    } else {
      swalfirefunctionality("error", "Image should be less than 2 MB", "error");
    }
  }

  const handleAddSubmit = (data) => {
    let reqData = data;
    reqData.email_id = reqData.email;
    reqData.image = firmImageName;
    reqData.created_by = "1";
    console.log(reqData, "reqData");
    const add_vacancy_api_result = FetchApiMethod(
      "addArticleshipplacement",
      reqData,
      "POST"
    );
    add_vacancy_api_result.then((res) => {
      try {
        if (res.message === "Articleship placement created successfully") {
          console.log(res, "res");
          swalfirefunctionality(
            `${res.data.firm_name} is added successfully`,
            "Good Job",
            "success"
          );
          reset();
          navigate("/articals");
        } else {
          swalfirefunctionality(`${res.error.message}`, "error", "error");
        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
      }
    });
  };

  const handlechange = (e) => {
    const numbersRegex = /^[0-9]*$/;
    if(numbersRegex.test(e.target.value) == false){
      const numericInput = e.target.value.replace(/\D/g, '');
      e.target.value = numericInput;
    }
  }
  const non_memberShipFormArr = [{  id: 1,  label: "Firm Name",  name: "firm_name",  type: "text",  required: "*",},{  id: 2,  label: "Firm Registration No.",  name: "frn_no",  type: "text",  required: "*",},{  id: 3,  label: "Firm Logo",  name: "image",  type: "file",  func1:imageUploadResult},{  id: 4,  label: "Contact Person",  name: "contact_person",  type: "text",  required: "*",},{  id: 5,  label: "Email",  name: "email",  type: "email",},{  id: 6,  label: "Contact Number",  name: "contact_no",  required: "*",  maxlength: "10",  minlength: "10",},{  id: 7,  label: "Position",  name: "position",  type: "text", required: "*",},{  id: 8,  label: "Total Vacancy",  name: "total_vacancy",  type: "number",required: "*", },{  id: 9,  label: "Stipend Range",  name: "stipend_range",  type: "text",  required: "*",},{  id: 10,  label: "City",  name: "city",  type: "text",  required: "*",},{  id: 11,  label: "Pincode",  name: "pincode",  type: "text",  required: "*",  maxlength:"6",  minlength:"6"},{  id: 12,  label: "Address",  name: "address",  type: "textarea",  required: "*",},];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(vacancyAddSchema),
  });
  return (
    <Container fluid className="p-0 mb-3">
      <Form
        aria-autocomplete="false"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(handleAddSubmit)}
      >
        <Container className="p-3 mt-3">
          <Card className="border-3 rounded-3 shadow">
            <Card.Header className="p-0 h3 px-3 allMainHeading">
              Submit a vacancy
            </Card.Header>
            <Card.Body>
              <Row>
                {non_memberShipFormArr?.map((elem, index) => (
                  <Col md={6} key={index}>
                    <Form.Group
                      className="mb-0 my-1"
                      controlId={`controlID_${elem.label}`}
                    >
                      <Form.Label>
                        {elem.label}
                        {elem.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </Form.Label>
                      {elem.name!="address" && elem.type!="file"?
                      <Form.Control
                        type={elem.type}
                        {...register(elem.name)}
                        isInvalid={!!errors[elem.name]}
                        placeholder={"Enter " + elem.label + "..."}
                        maxLength={elem.maxlength}
                        minLength={elem.minlength} 
                      />
                      :
                      elem.type=="file"?
                      <Form.Control
                      type={elem.type}
                      {...register(elem.name)}
                      isInvalid={!!errors[elem.name]}
                      placeholder={"Enter " + elem.label + "..."}
                      onChange={elem.type=="file"?elem.func1:""}
                    />
                    :
                      <Form.Control
                      as={elem.type}
                      rows={2}
                      {...register(elem.name)}
                      isInvalid={!!errors[elem.name]}
                      placeholder={"Enter " + elem.label + "..."}
                      maxLength={elem.maxlength}
                      minLength={elem.minlength}
                    />}
                      
                      <Form.Control.Feedback type="invalid">
                        {errors[elem.name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ))}
              </Row>
              <Container className="p-0 mt-1">
                <Row>
                  <Col className="text-right">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Form>
    </Container>
  );
}
export default AddVacancy;
