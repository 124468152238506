import * as Yup from "yup";

export const addMemberstudentvalidation = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    name: Yup.string().required("Name is required!"),
    membership_no: Yup.string().required('Reg No is required'),
    organisation_address: Yup.string().required("Address is required!"),
    phone: Yup.string().matches(
      /^[0-9]{10,11}$/,
      "Please enter valid number."
    ),
  });
  

  export const addMemberipvalidation = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    name: Yup.string().required("Name is required!"),
    membership_no: Yup.string().required('Membership No is required'),
    organisation_address: Yup.string().required("Organisation Address is required!"),
    dob: Yup.string().required("DOB is required!"),
    qual_date: Yup.string().required("Qualification Date is required!"),
    phone: Yup.string().matches(
      /^[0-9]{10,11}$/,
      "Please enter valid number."
    ),
  });

  export const addMembervalidation = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    organisation_name: Yup.string().required("Organisation Name is required!"),
    organisation_address: Yup.string().required("Organisation Address is required!"),
    phone: Yup.string().matches(
      /^[0-9]{10,11}$/,
      "Please enter valid number."
    ),
  });