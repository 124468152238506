import React from "react";
import "./IcaiCricketLeague.css";
import { Table } from "react-bootstrap";
import CricketImg from  "assets/img/theme/Tournament.png";
import TournamentDetails from  "assets/img/theme/TournamentDetails.png";
import venue1 from  "assets/img/theme/venue1.png";
import venue2 from  "assets/img/theme/venue2.png";
import venue3 from  "assets/img/theme/venue3.png";
import './BranchGrid.css';
import { BiSolidMap } from 'react-icons/bi';
import SponsorshipForm from "assets/img/theme/CIRC-SPONSORSHIP-FORM.pdf";

const branches = [
  { name: 'Agra', type: 'participation' },
  { name: 'Alwar', type: 'notparticipation' },
  { name: 'Aligarh', type: 'notparticipation' },
  { name: 'Ajmer', type: 'notparticipation' },
  { name: 'Beawar', type: 'notparticipation' },
  { name: 'Bharatpur', type: 'notparticipation' },
  { name: 'Bulandshahar', type: 'notparticipation' },
  { name: 'Bhilwara', type: 'notparticipation' },
  { name: 'Bilaspur', type: 'notparticipation' },
  { name: 'Bareilly', type: 'notparticipation' },
  { name: 'Bhopal', type: 'participation' },
  { name: 'Bhilai', type: 'notparticipation' },
  { name: 'Bikaner', type: 'notparticipation' },
  { name: 'Chittorgarh', type: 'notparticipation' },
  { name: 'Dehradun', type: 'notparticipation' },
  { name: 'Dhanbad', type: 'notparticipation' },
  { name: 'Indore', type: 'participation' },
  { name: 'GB Nagar', type: 'organiser' },
  { name: 'Ghaziabad', type: 'participation' },
  { name: 'Gorakhpur', type: 'notparticipation' },
  { name: 'Gwalior', type: 'notparticipation' },
  { name: 'Haridwar', type: 'notparticipation' },
  { name: 'Haldwani', type: 'notparticipation' },
  { name: 'Jamshedpur', type: 'notparticipation' },
  { name: 'Jaipur', type: 'participation' },
  { name: 'Jabalpur', type: 'notparticipation' },
  { name: 'Jhansi', type: 'participation' },
  { name: 'Jodhpur', type: 'notparticipation' },
  { name: 'Kota', type: 'participation' },
  { name: 'Kishangarh', type: 'notparticipation' },
  { name: 'Lucknow', type: 'participation' },
  { name: 'Muzaffarnagar', type: 'notparticipation' },
  { name: 'Mathura', type: 'notparticipation' },
  { name: 'Meerut', type: 'participation' },
  { name: 'Moradabad', type: 'notparticipation' },
  { name: 'Patna', type: 'participation' },
  { name: 'Pali', type: 'notparticipation' },
  { name: 'Prayagraj', type: 'participation' },
  { name: 'Raipur', type: 'participation' },
  { name: 'Ranchi', type: 'participation' },
  { name: 'Ratlam', type: 'notparticipation' },
  { name: 'Ujjain', type: 'notparticipation' },
  { name: 'Saharanpur', type: 'notparticipation' },
  { name: 'Sikar', type: 'notparticipation' },
  { name: 'Sri Ganganagar', type: 'notparticipation' },
  { name: 'Varanasi', type: 'notparticipation' },
  { name: 'Bhagalpur', type: 'notparticipation' },
  { name: 'Hanumangarh District', type: 'notparticipation' },
  { name: 'Satna', type: 'notparticipation' },
  { name: 'Darbhanga', type: 'notparticipation' },
  { name: 'Mandsaur', type: 'notparticipation' },
  { name: 'Muzaffarpur', type: 'notparticipation' },
  { name: 'Neemuch', type: 'notparticipation' },
  { name: 'Rajsamand', type: 'notparticipation' },
  { name: 'Kanpur', type: 'participation' },
  { name: 'Udaipur', type: 'participation' },
];

const IcaiCricketLeague = () => {

  const handleClick = () => {
    const pdfUrl = SponsorshipForm; // No need for curly braces here
    window.open(pdfUrl, '_blank');
};

  return (
    <>
    <div className="mx-5">
      {/* Introduction */}
      <div className="mt-md-5 mt-5"> 
        <div className="row">
           
          <div className="col-md-7 col-12">
            
            <h1 className="headingSize">
                Introduction to the           
                  <span className="text-success cricket-content"> Cricket </span>
                  Tournament           
            </h1>
            <div className="line-content bg-success mb-4"></div>
          
            <p className="text-justify px-0 col-11">
              The ClRC Cricket Tournament is an exciting sporting event
              organized by the Gautam Budh Nagar Branch of ClRC for CA
              professionals. This annual tournament brings together the best
              cricket teams and enthusiasts from the region to compete in a
              thrilling four-day Tournament.
              </p>
            <p className="mt-2">
              The tournament promises to be a showcase of talent,
              sportsmanship, and community spirit, providing a platform for
              professionals to demonstrate their cricket skills and for fans
              to enjoy the thrill of the game along with networking
              opportunities.
            </p>
            
          </div>

          <div className="col-md-5 col-12 d-flex align-items-start justify-content-center">
            <img
              alt="ICAI Logo"
              className="img-fluid mb-3"
              src={CricketImg}
              style={{ height: '80%', width: '85%' }}
            />
          </div>

        </div>       
                   
      </div> 

      {/* Objective */}
      <div className="col-md-12 px-0  mt-md-5 mt-5">
        <h1 className="headingSize">
         Objective
          <span className="text-success"> of  the Tournament</span>
        </h1>

        <div className="line-content bg-success mb-4"></div>

        <div className="row">
          <div className="col-md-3 col-12 mb-md-0 mb-3">
            <div
              className="border border-success "
              style={{minHeight: "17rem"   }}
            >
              <h6 className="text-white py-2 px-1 bg-success text-center">
                  Boosting Health & Well Being for CA's
              </h6>
              <p className="  p-3 ">
                The Tournament encourages physical activity through cricket,
                it seeks to reduce stress and promoting overall mental and
                physical fitness in the profession.
              </p>
            </div>
          </div>

          <div className="col-md-3 col-12 mb-md-0 mb-3">
            <div
              className="border border-success "
              style={{minHeight: "17rem"  }}
            >
              <h6 className="text-white text-justify bg-default py-2 px-1 text-center">
                Encourage Community Engagement
              </h6>
              <p className="  p-3">
                By bringing together cricket enthusiasts from Gautam Budh
                Nagar and surrounding areas, the tournament will promote
                community spirit and social interaction.
              </p>
            </div>
          </div>

          <div className="col-md-3 col-12 mb-md-0 mb-3">
            <div
              className="border border-success "
              style={{minHeight: "17rem"   }}
            >
              <h6 className="text-white text-justify bg-success py-2 px-1 text-center">
                Skill Development
              </h6>
              <p className="  p-3" >
                The event offers opportunities for players to improve their
                cricketing skills through intense competition.
              </p>
            </div>
          </div>

          <div className="col-md-3 col-12 mb-md-0 mb-3">
            <div
              className="border border-success "
              style={{ minHeight: "17rem"  }}
            >
              <h6 className="text-white text-justify bg-default py-2 px-1 text-center">
                Enhance Regional Identity
              </h6>
              <p className=" p-3">
                The tournament aims to strengthen the identity of the Gautam
                Budh Nagar region as a hub for sports and cultural
                activities, boosting local pride and unity.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">


        {/* </div> */}


        <br></br>
        <br></br>
        <br></br>

        {/* ICAI hierarchy */}
      <div className="hierarchy-container">
      <div className="main-node">
        <div className="icai-box">ICAI</div>
      </div>
      <div className="children-nodes">
        <div className="node">
          <span className="circle">1</span>
          <p>
            Western India <br />
            Regional Council <br />
            <strong>(WIRC)</strong>
          </p>
        </div>
        <div className="node">
          <span className="circle">2</span>
          <p>
            Southern India <br />
            Regional Council <br />
            <strong>(SIRC)</strong>
          </p>
        </div>
        <div className="node">
          <span className="circle">3</span>
          <p>
            Central India <br />
            Regional Council <br />
            <strong>(CIRC)</strong>
          </p>
        </div>
        <div className="node">
          <span className="circle">4</span>
          <p>
            Eastern India <br />
            Regional Council <br />
            <strong>(EIRC)</strong>
          </p>
        </div>
        <div className="node">
          <span className="circle">5</span>
          <p>
            Northern India <br />
            Regional Council <br />
            <strong>(NIRC)</strong>
          </p>
        </div>
      </div>
      </div>
      <br></br>
      <br></br>
      <br></br>


      {/* Regional Counciles */}

      <div className="branch-grid">
        <h2 className="headingSize">Central India Regional Council (CIRC)</h2>
        <h1 className="headingSize">CIRC Branches</h1>
      <div className="grid-container">
        {branches.map((branch, index) => (
          <div key={index} className={`branch-item ${branch.type}`}>
            <BiSolidMap className="icon align-items-left text-start" />
            <span>{branch.name}</span>
          </div>
        ))}
      </div>
      
      {/* Legend directly inside BranchGrid */}
      <div className="legend">
          <div className="legend-item">
            <div className="square organiser"></div>
            <span>ORGANISER</span>
          </div>
          <div className="legend-item">
            <div className="square participation"></div>
            <span>PARTICIPATION TEAM</span>
          </div>
        </div>
      </div>

      </div>

      {/* Tournament Details */}
      <div className="mt-md-5 mt-5 ">
        <h1 className="headingSize">
          {" "}
          Tournament <span className="text-success">Details</span>
        </h1>
        <div className="row align-items-top">
          
          <div className=" col-md-6 col-12 ">
                       
            <div className="line-content bg-success mb-4"></div>

            <p >
              The CIRC Cricket Tournament will be held from November 9-12,
              2024 in Noida, Gautam Budh Nagar. The tournament will feature 16
              teams competing among themselves in four groups.
            </p>
            
            <Table  className=" table-bordered border-success mt-md-3 mt-3">
              <thead>
                <tr>
                  <th>Tournament Dates</th>
                  <th>November 9 - 12,2024</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td>Venue</td>
                  <td><b>Noida, Gautam Budh Nagar</b></td>
                </tr>
                <tr>
                  <td>Format</td>
                  <td><b>T-20</b></td>
                </tr>
                <tr>
                  <td>Number of Teams</td>
                  <td colSpan={2}><b>16</b></td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-md-6 col-12  d-flex justify-content-center ">
            <img
              alt="ICAI Logo"
              className="img-fluid "
              src={TournamentDetails}
              style={{ height: '81%', width: '65%' }}
            />
          </div>
        </div>
      </div>
      
      {/* Venue */}
      <div className=" mt-md-0 mt-5 ">
        <h1 className="headingSize">
            {" "}
            Venue and <span className="text-success">Facilities</span>
        </h1>         
        <div className="line-content bg-success mb-4"></div>
          <div className="row  align-items-center">
            <div className="col-md-6  col-12 ">
              {" "}
              <h3  className="mb-3">
                {" "}
                Noida Cricket <span className="text-success">Stadium</span>
              </h3>
              <p >
                The tournament will be held at Noida Cricket Stadium, a
                world-class facility equipped with modern amenities and a
                seating capacity of 15,000 spectators.{" "}
              </p>
              <div className="line-content"></div>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center mb-sm-3 mb-3">
              <img
                alt="ICAI Logo"
                className="img-fluid"
                src={venue1}
                style={{ height: '85%', width: '70%' }}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center mb-sm-3 mb-3 ">
              <img
                alt="ICAI Logo"
                className="img-fluid"
                src={venue2}
                style={{ height: '85%', width: '71%' }}
              />
            </div>
            <div className="col-md-6 col-12 ">
            <h3 className="mb-3">
              {" "}
              Premier <span className="text-success">Facilities</span>
            </h3>
            <p>
              The stadium boasts world-class facilities including practice nets,
              locker rooms, medical facilities, and hospitality areas to ensure
              a top-notch experience for players and guests.{" "}
            </p>
            <div className="line-content"></div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-6 col-12 ">
              {" "}
              <h3 className="mb-3">
                {" "}
                Passionate  <span className="text-success">Crowds</span>
              </h3>
              <p>
              The tournament is expected to draw large, enthusiastic crowds from
              the Gautam Budh Nagar region along with CA professionals, creating
              an electric atmosphere and heightening the excitement for players
              and sponsors alike.
              </p>
              <div className="line-content"></div>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center mb-sm-3 mb-3">
              <img
                alt="ICAI Logo"
                className="img-fluid"
                src={venue3}
                style={{ height: '85%', width: '70%' }}
              />
            </div>
          </div>
      </div>
      
      {/* Sponsorship Opportunities */}
      <div className="mt-md-5 mt-5">
        <h1  className="headingSize">
          Sponsorship <span className="text-success">Opportunities</span>
        </h1>
        <div className="line-content bg-success mb-4"></div>
        <div className="row ">
          <div className="col-md-3 col-12 mb-md-0 mb-5  border-primary borderRight borderBottom">
            {" "}
            <div className="align-content-center text-center">
              <button className="test rounded-circle text-white   bg-success border-0">
              Title Sponsorship
              </button>
            </div>
            <p  className="mt-md-3 mt-3 ">
              The premier sponsorship opportunity, your company's name and
              logo will be prominently displayed throughout the tournament.
            </p>
          </div>

          <div className="col-md-3 col-12 mb-md-0 mb-5   border-primary borderRight borderBottom">
            {" "}
            <p  className="text-justify">
              Align your brand with the tournament through branding on
              player uniforms, digital platforms, and event signage.
            </p>
            <div className="align-content-center text-center">
            <button class="test  mt-md-5 my-md-0 my-4 rounded-circle text-white   px-2 bg-default border-0">
              Associate Sponsorship
            </button></div>
          </div>
          <div className="col-md-3 col-12 mb-md-0 mb-5  border-primary borderRight borderBottom">
            {" "}
            <div className="align-content-center text-center">
            <button class="test rounded-circle text-white bg-success border-0 px-2 border-0 ">
              Hospitality Packages
            </button></div>
            <p  className="mt-md-3 mt-3">
              Provide VIP experiences for your clients and guests with
              exclusive access to team dressing rooms and player
              meet-and-greets.
            </p>
          </div>
          <div className="col-md-3 col-12 mb-md-0 mb-5">
            {" "}
            <p  className="text-justify">
              Promote your products and services through advertising spots
              during live-streamed matches and in the tournament program.
            </p>
            <div className="align-content-center text-center">
            <button class="test rounded-circle text-white  bg-default border-0">
              Advertising Opportunities
            </button></div>
          </div>
        </div>

        <Table bordered className="border-success mt-5 table-responsive-md table-responsive-sm">
          <thead className="py-1 bg-success text-white ">
            <tr>
              <th scope="col" className="text-nowrap">
                S No.
              </th>
              <th scope="col">Sponsorship Benefits</th>
              <th scope="col">Platinum</th>
              <th scope="col">Gold</th>
              <th scope="col">Silver</th>
              <th scope="col">Bronze</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Sponsorship Package</td>
              <td><span><i class="fa fa-inr" aria-hidden="true"></i></span>{" "}15,00,000</td>
              <td><span><i class="fa fa-inr" aria-hidden="true"></i></span>{" "}10,00,000</td>
              <td><span><i class="fa fa-inr" aria-hidden="true"></i></span>{" "}5,00,000</td>
              <td><span><i class="fa fa-inr" aria-hidden="true"></i></span>{" "}2,50,000</td>
            
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Number of Sponsorship</td>
              <td>1</td>
              <td>2</td>
              <td>4</td>
              <td>10</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td colspan="1">Official Title Sponsor</td>
              <td>&#10004;</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td colspan="1">(Powered by) Sponsorship</td>
              <td></td>
              <td>&#10004;</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td colspan="1">
                  In-Stadium Signage and Branding: Utilize
                strategically placed banners, flags, and digital screens
                within the stadium to ensure maximum visibility for the
                sponsor's brand during matches and events. 1. Banner 2.
                Standee 3. Logo on Umpire Uniform 4. Toss
              </td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td colspan="1">
                On Ground Activation -Set up branded booths, displays, or
                interactive zones at the venue where attendees can engage with
                the sponsor's products or services. Consider offering samples,
                demonstrations, or exclusive promotions to drive brand
                engagement.
              </td>
              <td>
                2 Booth per Match with 2 Representatives (Near Doughout Area)
              </td>
              <td>1 Booth per Match with 2 Representatives</td>
              <td>
                2 Standee Per Match with 1 Representatives each per standee
              </td>
              <td>1 Standee Per Match with 1 Representatives</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td colspan="1">
                Digital Presence: Leverage the tournament's digital platforms,
                including official websites, social media pages, and mobile
                apps, to showcase the sponsor's logo, banner ads, and
                promotional content. Encourage engagement through contests
                polls and interactive
              </td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td colspan="1">
                1. Customized Awards 2. Photo Opportunity with award winners
                3. Branding and Recognition
              </td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td colspan="1">
                Live Streaming Advertisement -Youtube/ Any Other Cric App
              </td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td colspan="1">
                Unveiling Ceremony: Create anticipation by building up to the
                moment of unveiling the trophy. Use dramatic lighting or music
                to enhance the atmosphere as the trophy is revealed to the
                audience and media. 1. Celebrity /lnfluencer/Cheif Guest
                Interaction 2. Media Interviews and Press Releases 3.
                Customized Photo Opportunities with Team Captains 4. Branded
                Backdrops and Signage 5. Social Media Engagement
              </td>
              <td>All Benefits</td>
              <td>All Benefits</td>
              <td>Point 3 to 5</td>
              <td>Point 3 to 5</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td colspan="1">
                Sponsorship of Match Moments: Sponsor specific match moments
                such as 'Player of the Match', 'Best Batsman', "Impact Player
                of the Match" or 'Bowler of the Match
              </td>
              <td>Player of the Match Impact Player of the Match </td>
              <td>Best Batsman</td>
              <td>Bowler of the Match</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td colspan="1">
                Post-Tournament Recap: After the tournament, leverage the
                event's success by sharing highlights, behind-the-scenes
                footage, and success stories across digital and traditional
                media platforms. This reinforces brand visibility and
                association with the tournament's positive outcomes.
              </td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
              <td>&#10004;</td>
            </tr>
          </tbody>
        </Table>

      </div>


         
      {/* Sponsorship Form */}
      <div className="mt-md-5 mt-5 ">
        <div className="row ">
          <div className=" col-12">
            <h1 className="headingSize"><span className="text-success">Sponsorship </span>Form</h1>
            <div className="line-content bg-success mb-2"></div>
            <h3 className="mb-0 WantSponsorshipText">Want to hit a sponsorship six at the CIRC Cricket Tournament? Download the
              <span>  <div className="sponsorship-form"  onClick={handleClick}>
              <span>SPONSORSHIP</span>&nbsp;
              <span className="highlight">FORM &nbsp;
              <i className="fa fa-download text-default iconBgDonload" aria-hidden="true" style={{cursor: "pointer"}}></i>
              </span>
            </div></span>  And Join the fun!</h3>
            <h6>
              Email:<a href="mailto:chairman@icai-noida.org" ><span className="text-success"> chairman@icai-noida.org </span></a>
            </h6>
            <h6>
                Phone: <a href="tel:+919871880815"><span className="text-success"> +91-9871880815 | +91- 9953197540</span></a>
            </h6>
              <p>Please complete the form and send the scanned copy of the PDF to our registered email address.</p>        
          </div>

          

          {/* <div className="tournament-section border border-primary rounded-4 borderRadius"> */}
          {/* <div className="sponsorship-container mt-3">
            <div className="tournament-badge">Cricket Tournament 2024
            <div className="sponsorship-form"  onClick={handleClick}>
              <span>SPONSORSHIP</span>&nbsp;
              <span className="highlight">FORM &nbsp;
              <i className="fa fa-download text-default iconBgDonload" aria-hidden="true" style={{cursor: "pointer"}}></i>
              </span>
            </div></div>
            
          </div> */}
          

        </div>
      </div>






      

    

  </div>  
   </>
  );
};

export default IcaiCricketLeague;