import React, { useState, useEffect } from "react";
import IcaiCarousel from "components/HomeComponents/IcaiCarousel";
import EventCards from "components/HomeComponents/EventCards";
import Chairman from "components/HomeComponents/ChairmanMsg";
import NoticeTab from "components/HomeComponents/NoticeTab";
import TeamSection from "components/HomeComponents/OfficeBearer";
import PublicationCards from "components/HomeComponents/Publication";
import IcaiResources from "components/HomeComponents/IcaiResources";
import FetchApiMethod from "Helpers/FetchApiMethod";

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [events, setEvents] = useState([]);
  const [chairmanmsg, setChairmanMsg] = useState([]);
  const [officebearer, setOfficeBearer] = useState([]);
  const [publication, setPublication] = useState([]);
  const [notification, setNotification] = useState([]);
  const [countercard, setCounter] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventsData, bannersData, officeBearerData, chairmanMsgData, publicationData, notificationData, counterData] = await Promise.all([
          FetchApiMethod("event/getEvents?postman=Y&status=Active", "POST"),
          FetchApiMethod("getBannerswebsite?postman=Y&banner_type=homepage", "POST"),
          FetchApiMethod("getChairmanOfficeBearersnew?postman=Y&display_y=Y", "POST"),
          FetchApiMethod("getChairma?postman=Y", "POST"),
          FetchApiMethod("event/getPublicationsnewsletter?postman=Y", "POST"),
          FetchApiMethod("getNoticeboardwebsite?postman=Y", "POST"),
          FetchApiMethod("NoticeCount", "POST")
        ]);
        setEvents(eventsData.data || []);
        setBanners(bannersData.data || []);
        setOfficeBearer(officeBearerData.data || []);
        setChairmanMsg(chairmanMsgData.data || []);
        setPublication(publicationData.data || []);
        setNotification(notificationData.data || []);
        setCounter(counterData.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <IcaiCarousel banners={banners} />
      <EventCards eventsData={events} />
      <Chairman ChairmanMsg={chairmanmsg} />
      <NoticeTab Notification={notification} counterCard={countercard} />
      <TeamSection officeBearer={officebearer} />
      <PublicationCards publicationData={publication} />
      <IcaiResources />
    </>
  );
};

export default Home;
