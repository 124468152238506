/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Home from "views/IcaiPages/HomePages/Home.js";
import ChairmanProfile from "views/IcaiPages/ChairmanMsg";
import AboutIcai from "views/IcaiPages/AboutPages/AboutIcai";
import EventsDetails from "views/IcaiPages/UpcomingEvents/EventDetails";
import PastDetails from "views/IcaiPages/PastEvents/PastDetails";
// import UpcomingEvents from  'views/IcaiPages/UpComingEvent';
import UpcomingEvents from "views/IcaiPages/UpcomingEvents/UpcomingEvents";
import MemberRegForm from "views/IcaiPages/UpcomingEvents/MemberRegForm";
import RecentPhotos from "views/IcaiPages/RecentPhotos/RecentPhotos";
import OurGallery from "views/IcaiPages/RecentPhotos/OurGallery";
import PastChairPersons from "views/IcaiPages/PastChairPersons/PastChairPersons";
import Articals from "views/IcaiPages/Articalspages/Articals";
import AddVacancy from "views/IcaiPages/VacancyPage/AddVacancy";
import PastEvents from "views/IcaiPages/PastEvents/PastEvents";
import WebsiteLayout from "Layouts/websiteLayout";
import NetworkingZone from "views/IcaiPages/NetworkingZone/NetworkingZone";
import AddNetworkZone from "views/IcaiPages/NetworkingZone/AddNetworkZone";
import ICAIRegistrationForm from "../src/views/ICAIRegistrationForm";
import ContactPage from "views/IcaiPages/ContactForm/ContactPage";
import NoticeDetails from "views/IcaiPages/Noticedetails";
import PublicationNews from "views/IcaiPages/PublicationNews/PublicationNews";
import { Container } from "react-bootstrap";
// import "react-responsive-pagination/themes/minimal.css";
import "react-responsive-pagination/themes/classic.css";
import OurResources from "views/IcaiPages/OurResources";

import CalenderEvents from "views/IcaiPages/CalenderEventsPages/CalenderEvents";
import DownloadResources from "views/IcaiPages/DownloadResources.js";
import StudyCircleDetail from "views/IcaiPages/ViewStudyCircle";
import PublicationNewsletterDet from "views/IcaiPages/PublicationNews/PublicationNewsletterDet";
import TransactionStatusForm from "views/IcaiPages/TransactionStatus/TransactionForm";
// import OurGallery from './views/IcaiPages/RecentPhotos/OurGallery';
import IcaiCricketLeague from "views/IcaiCricketLeague/IcaiCricketLeague";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <ScrollToTop />
    <Container fluid className="p-0 overflow-hidden">
      <WebsiteLayout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/landing-page" exact element={<Landing />} />
          <Route path="/login-page" exact element={<Login />} />
          <Route path="/profile-page" exact element={<Profile />} />
          <Route path="/register-page" exact element={<Register />} />
          <Route path="/home-page" exact element={<Home />} />
          <Route path="/upcoming-events" exact element={<UpcomingEvents />} />
          <Route path="/chairman-message" exact element={<ChairmanProfile />} />
          <Route path="/About-ICAI" exact element={<AboutIcai />} />
          <Route path="/Member-Reg" exact element={<MemberRegForm />} />
          <Route path="/Recent-Photos" exact element={<RecentPhotos />} />
          <Route path="/Our-Gallery" exact element={<OurGallery />} />
          <Route path="/ICAI-Registration-From" exact element={<ICAIRegistrationForm />} />
          <Route
            path="/Past-ChairPersons"
            exact
            element={<PastChairPersons />}
          />
          <Route
            path="/upcoming-events-details"
            exact
            element={<EventsDetails />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/articals" exact element={<Articals />} />
          <Route path="/addVacancy" exact element={<AddVacancy />} />
          <Route path="/Past-Events" exact element={<PastEvents />} />
          <Route path="/networkingZone" exact element={<NetworkingZone />} />
          <Route path="/addNetworkZone" exact element={<AddNetworkZone />} />
          <Route path="/Past-Events-Details" exact element={<PastDetails />} />
          <Route path="/Contact-Us" exact element={<ContactPage />} />
          <Route path="/Notice-Details" exact element={<NoticeDetails />} />
          <Route path="/Publication-News" exact element={<PublicationNews />} />
          <Route path="/OurResources" exact element={<OurResources />} />
          <Route path="/CalenderEvents" exact element={<CalenderEvents />} />

          <Route path="/Download-Resources" exact element={<DownloadResources />} />
          <Route path="/Study-Circle" exact element={<StudyCircleDetail />} />
          <Route path="/PublicationNewsletter-Detail" exact element={<PublicationNewsletterDet />} />
          <Route path="/Transaction-Status" exact element={<TransactionStatusForm />} />
          <Route path="/Icai-Cricket-League" exact element={<IcaiCricketLeague />} />
        </Routes>
      </WebsiteLayout>
    </Container>
  </BrowserRouter>
);
