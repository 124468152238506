import React, { useState, useEffect } from "react";
import PublicationNewsComponents from "components/PublicationNewsComponents/PublicationNewsComponents.js";
import FetchApiMethod from "Helpers/FetchApiMethod";

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);

  const getAllEvents = async () => {
    try {
      const getPublicationsNewsletter = await FetchApiMethod(
        "event/getPublicationsnewsletter?postman=Y",
        "POST"
      );
      setEvents(getPublicationsNewsletter.data || []);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  console.log(events, 'line no 36');

  return (
    <div>
      <PublicationNewsComponents eventsData={events} />
    </div>
  );
};

export default UpcomingEvents;
