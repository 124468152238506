import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ButtonIcon from "views/IndexCompnents/ButtonsSection/ButtonIcon";
import RoundedImage from "views/IndexCompnents/ImagesSection/RoundedImg";
import DummyImg2 from "assets/img/theme/DummyImg2.jpg";
import { imageURL } from "components/URLs/URL";

const TeamSection = ({ officeBearer }) => {
  return (
    <section className="section">
      <div className="m-0 container-fluid">
        <Row className="justify-content-center text-center mb-4">
          <Col lg="8">
            <h3 className="display-3 text-center allMainHeading">Our Office Bearers</h3>
          </Col>
        </Row>
        {chunkArray(officeBearer, 5).map((row, index) => (
          <Row key={index} className="mx-1 justify-content-center">
            {row.map((member, i) => (
              <Col key={`${i}_col`} lg="2" sm="6" className="mb-5 mx-1 p-0">
                <Card className="pt-4 px-2 m-0" style={{ height: "360px" }}>
                  <RoundedImage src={member.images ? `${imageURL}/public/img/${member.images}` : DummyImg2} />
                  <CardBody className="p-1 mt-2 align-content-center text-center">
                    <h5 className="title">
                      <span className="h6 d-block font-weight-bolder
                      ">{member.name}</span>
                      <small className="h6 text-default">{member.designation}</small>
                    </h5>
                    {/* Rendering social media buttons */}
                    <div>
                      <ButtonIcon className="btn-default btn-sm" href={getSocialLink(member.social_links, "twitter") ? `javascript:void(window.open('${getSocialLink(member.social_links, "twitter")}', '_blank'))` : "#"}>
                          <i className="fa fa-twitter" />
                      </ButtonIcon>
                      <ButtonIcon className="btn-primary btn-sm" href={getSocialLink(member.social_links, "facebook") ? `javascript:void(window.open('${getSocialLink(member.social_links, "facebook")}', '_blank'))` : "#"}>
                          <i className="fa fa-facebook" />
                      </ButtonIcon>
                      <ButtonIcon className="btn-info btn-sm" href={getSocialLink(member.social_links, "linkedIn") ? `javascript:void(window.open('${getSocialLink(member.social_links, "linkedIn")}', '_blank'))` : "#"}>
                          <i className="fa fa-linkedin" />
                      </ButtonIcon>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </section>
  );
};

// Function to get the social link based on type
const getSocialLink = (socialLinks, type) => {
  const link = socialLinks.find(link => link.link_type === type);
  return link ? link.link : "#";
};

// Function to split array into chunks
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export default TeamSection;
