import React, { useState, useEffect } from "react";
import ImageComponents from "components/ImageComponents/ImageComponents.js";
import FetchApiMethod from "Helpers/FetchApiMethod";

const RecentPhotos = () => {
  const [photos, setPhotos] = useState([]);

  const getAllPhotos = async () => {
    try {
      const getRecentPhotosApiResult = await FetchApiMethod(
        "ICAIphotogallery?postman=Y",
        "POST"
      );
      setPhotos(getRecentPhotosApiResult.data || []);
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  };

  useEffect(() => {
    getAllPhotos();
  }, []);

  console.log(photos, 'line no 36');
  
  return (
    <div>
      <ImageComponents photosData={photos} />
    </div>
  );
};

export default RecentPhotos;
