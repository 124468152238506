import React from "react";
// reactstrap components
import {
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import ButtonIcon from "views/IndexCompnents/ButtonsSection/ButtonIcon";
function MyFooter() {
    return (
        <>
       
    <div className="container-fluid pb-0 MainBackgroundColour">

            <Row className=" row-grid  deepakCSS mb-2">
              <Col lg="3">
                <h4 className="text-white">Members</h4>
              <ul className="list-unstyled mt-2">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=1820&c_id=82" target="_blank" className="text-white">Know Rules & Regulations</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/Members.html?mod=2" target="_blank" className="text-white">Post-Qualification Courses</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://cabf.icai.org/voluntaryMember" target="_blank" className="text-white">Generously Contribute to CABF</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.cpeicai.org/" target="_blank" className="text-white">Know the CPE Hours Credit Status</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://help.icai.org/" target="_blank" className="text-white">e-Sahaayataa</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=634&c_id=227" target="_blank" className="text-white">Examinations</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/Members.html?mod=4" target="_blank" className="text-white">Empanelment</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=5509" target="_blank" className="text-white">e-Services</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/post/invitation-for-empanelment-as-examiners-for-chartered-accountants-examinations-20-09-2017" target="_blank" className="text-white">Become Examiner with ICAI</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_category.html?c_id=227" target="_blank" className="text-white">Other Imp. Links</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
              </Col>
              <Col lg="3">
                <h4 className="text-white">Students</h4>
              <ul className="list-unstyled mt-2">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://cloudcampus.icai.org/" target="_blank" className="text-white">ICAI Cloud Campus</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=5720&c_id=314" target="_blank" className="text-white">BoS Knowledge Portal</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/Students.html?mod=1" target="_blank" className="text-white">BoS Announcements</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://resource.cdn.icai.org/19323ca_atcourse260510.pdf" target="_blank" className="text-white">At a Glance: CA Course</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://help.icai.org/" target="_blank" className="text-white">e-Sahaayataa</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=634&c_id=227" target="_blank" className="text-white">Examinations</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=5509" target="_blank" className="text-white">e-Services</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
              </Col>
              <Col lg="3">
                <h4 className="text-white">Resources</h4>
              <ul className="list-unstyled mt-2">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=2805&c_id=221" target="_blank" className="text-white">Download Standards</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_category.html?c_id=218" target="_blank" className="text-white">View Notifications</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=501&c_id=222" target="_blank" className="text-white">Look up Guidance Notes & Interpretations</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://learning.icai.org/elearning" target="_blank" className="text-white">Digital Learning Hub</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://icai-cds.org/" target="_blank" className="text-white">Centralized Distribution System</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/new_post.html?post_id=6628" target="_blank" className="text-white">Free Download - Publications</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://icai-cds.org/" target="_blank" className="text-white">ICAI Publications</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/Committees.html" target="_blank" className="text-white">Committee Pages</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/post.html?post_id=11657" target="_blank" className="text-white">Other Useful Links</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
              </Col>
              <Col lg="3">
                <h4 className="text-white">Other Websites</h4>
              <ul className="list-unstyled mt-2">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.icai.org/" target="_blank" className="text-white">ICAI</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://circ.icai.org/" target="_blank" className="text-white">CIRC</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://eirc-icai.org/" target="_blank" className="text-white">EIRC</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://nirc.icai.org/" target="_blank" className="text-white">NIRC</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://www.sirc-icai.org/" target="_blank" className="text-white">SIRC</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                              <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                          </div>
                          <div>
                            <h6 className="mb-0">
                              <a href="https://wirc-icai.org/" target="_blank" className="text-white">WIRC</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-white">Connect With Us</h4>
                        <ul className="list-unstyled mt-2">
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                    <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                                </div>
                                <div>
                                  <h6 className="mb-0">
                                    <a href="/Contact-Us" className="text-white">Contact Us</a>
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="d-flex align-items-center">
                                <div>
                                    <i className="ni ni-atom  text-white pr-2" style={{fontSize: "12px"}} />
                                </div>
                                <div>
                                  <h6 className="mb-0">
                                    <a href="https://wa.me/919289791254" target="_blank" className="text-white">Click to Connect</a>
                                  </h6>
                                </div>
                              </div>
                            </li>
                        </ul>
                        <ul className="list-unstyled mt-2 d-flex justify-content-start">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <ButtonIcon className="btn-primary" id="tooltipfb" target="_blank" href={`javascript:void(window.open('https://www.facebook.com/theicai', '_blank'))`}>
                                  <i className="fa fa-facebook-square" />
                                </ButtonIcon>
                                <UncontrolledTooltip delay={0} placement="bottom" target="tooltipfb" trigger="hover focus"> Like Us </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>&nbsp;&nbsp;
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <ButtonIcon className="btn-danger" id="tooltipInsta" target="_blank" href={`javascript:void(window.open('https://www.instagram.com/icaiorg/', '_blank'))`}>
                                  <i className="fa fa-instagram" />
                                </ButtonIcon>
                                <UncontrolledTooltip delay={0} placement="bottom" target="tooltipInsta" trigger="hover focus"> Follow Us </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>&nbsp;&nbsp;
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <ButtonIcon className="btn-default" id="tooltipIn" target="_blank"  href={`javascript:void(window.open('https://www.linkedin.com/school/theicai/', '_blank'))`}>
                                  <i className="fa fa-linkedin-square" />
                                </ButtonIcon>
                                <UncontrolledTooltip delay={0} placement="bottom" target="tooltipIn" trigger="hover focus"> Connect with Us </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>&nbsp;&nbsp;
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <ButtonIcon className="btn-info" id="tooltipIn" target="_blank"  href={`javascript:void(window.open('https://twitter.com/theicai', '_blank'))`}>
                                  <i className="fa fa-twitter" />
                                </ButtonIcon>
                                <UncontrolledTooltip delay={0} placement="bottom" target="tooltipIn" trigger="hover focus"> Connect with Us </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>&nbsp;&nbsp;
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <ButtonIcon className="btn-danger" id="tooltipIn" target="_blank"  href={`javascript:void(window.open('https://www.youtube.com/icaiorgtube', '_blank'))`}>
                                  <i className="fa fa-youtube" />
                                </ButtonIcon>
                                <UncontrolledTooltip delay={0} placement="bottom" target="tooltipIn" trigger="hover focus"> Connect with Us </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>&nbsp;&nbsp;
                        </ul>
              </Col>
            </Row>
            {/* <Row className="bg-white  row-grid align-items-center justify-content-center pt-2">
                <Col lg="2">
                    <div className="text-center ">
                        <p class="heading m-0 text-black text-bold">10000+</p>
                        <p class="description text-black text-bold">Total Visits</p>
                    </div>
                </Col>
                <Col lg="2">
                    <div className=" text-center ">
                        <p class="heading m-0 text-black text-bold">3000+</p>
                        <p class="description text-black text-bold">Yesterday</p>
                    </div>
                </Col>
                <Col lg="2">
                    <div className="text-center">
                        <p class="heading m-0 text-black text-bold">1500+</p>
                        <p class="description text-black text-bold">Today</p>
                    </div>
                </Col>
            </Row> */}
            <Row className="row-grid align-items-center justify-content-center MainBackgroundColour">
                {/* <Col lg="6" className="text-center" onClick={() => window.open("https://proeffico.com/", "_blank")}>
                    <p className="text-white">© Gautam Budh Nagar Branch by Proeffico Solutions Private Limited.</p>
                </Col> */}

                <Col lg="6" className="text-center" onClick={() => window.open("https://proeffico.com/", "_blank", "noopener")}>
                    <p className="text-white">© Gautam Budh Nagar Branch by Proeffico Solutions Private Limited.</p>
                </Col>
            </Row>
    </div>
    </>
    );
}
export default MyFooter;