import * as Yup from "yup";

export const vacancyAddSchema = Yup.object().shape({
  firm_name: Yup.string().required("Firm name is required").matches(/^[A-Z a-z]+$/, "Must be only letters"),
  contact_person: Yup.string().required("Contact person is required"),
  frn_no: Yup.string().required("FRN is required"),
  pincode: Yup.string().required("Pindode is required").matches(/^[0-9]+$/, "Must be only digits").min(6, "Must be exactly 6 digits").max(6, "Must be exactly 6 digits"),
  stipend_range: Yup.string().required("Stipend range ex: (1xxx-5xxx)").matches(/^\d{4,5}-\d{4,5}$/, "Range should be 1000-5000, 5000-10000, 10000-15000..."),
  city: Yup.string().required("City is required").matches(/^[A-Z a-z]+$/, "Must be only letters"),
  address: Yup.string().required("Address is required"),
  position: Yup.string().required("Position is required").matches(/^[A-Z a-z]+$/, "Must be only letters"),
  contact_no: Yup.string().required('Contact is required').matches(/^[0-9]+$/, "Must be only digits").min(10, "Must be exactly 10 digits").max(10, "Must be exactly 10 digits"),
  total_vacancy: Yup.string().required("Total vacancy is required").matches(/^[1-9]/,"Must be only numbers"),
});


