import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

// import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  // Container,
  Row,
  Col,
} from "reactstrap";

const handleCollapseNavbar = () => {
  const navbarCollapse = document.getElementById("navbar-collapse");
  if (navbarCollapse) {
    navbarCollapse.classList.remove("show");
  }
};

function IcaiNavBar(){
  const handleCollapseNavbar = () => {
    const navbarCollapse = document.getElementById("navbar-collapse");
    if (navbarCollapse) {
      navbarCollapse.classList.remove("show");
    }
  };

// function IcaiNavBar(){

  return (
    <>
        <Navbar className="navbar-dark  mt-1 py-0 sticky-top"  expand="lg" style={{backgroundColor:"#0d0d65"}}>
        <div className="container-fluid align-items-center justify-content-between px-4">
             <NavbarBrand  to="/" tag={Link} className="text-white text-capitalize">
              Home
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-primary">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-primary" id="navbar-collapse">
              <div className="navbar-collapse-header">
                 <Link to="/">
                  <img onClick={handleCollapseNavbar} style={{ width: '60px', height: '50px' }}
                    alt="Brand Logo"
                    src={require("assets/img/brand/argon-react.png")}
                  />
                </Link>
                <div class="d-flex align-items-center justify-content-end">
                  <button class="navbar-toggler justify-content-end onlyCloseIcon" id="navbar-primary">
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>

              {/* <div class="navbar-collapse-header flex items-center justify-content-between headerHidden">
                <Link to="/">
                  <img onClick={handleCollapseNavbar} style={{ width: '60px', height: '50px' }}
                    alt="Brand Logo"
                    src={require("assets/img/brand/argon-react.png")}
                  />
                </Link>
                <div class="d-flex align-items-center justify-content-end">
                  <button class="navbar-toggler justify-content-end onlyCloseIcon" id="navbar-primary">
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div> */}

              <Nav className="mr-lg-auto" navbar>
                {/* <NavItem>
                  <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                    Overview <span className="sr-only">(current)</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                    Member
                  </NavLink>
                </NavItem> */}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  className=" py-2">Overview</DropdownToggle>
                  <DropdownMenu
                    aria-labelledby="navbar-primary_dropdown_1"
                    left
                  >
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/About-ICAI" tag={Link}
                    >
                      About Us
                    </DropdownItem>
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Office Bearer
                    </DropdownItem> */}
                    {/* <DropdownItem divider /> */}
                    {/* <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/Past-ChairPersons" tag={Link}
                    >
                      Former Chairpersons
                    </DropdownItem> */}
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/Recent-Photos" tag={Link}
                    >
                      Event Gallery
                    </DropdownItem>
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/Our-Gallery" tag={Link}
                    >
                      Our Student Gallery
                    </DropdownItem>
                    {/* <DropdownItem
                      to="/upcoming-events" tag={Link}
                    >
                      Upcoming Events
                    </DropdownItem> */}
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/Past-Events" tag={Link}
                    >
                      Past Events
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  className=" py-2">Members</DropdownToggle>
                  <DropdownMenu
                    aria-labelledby="navbar-primary_dropdown_2"
                    left
                  >
                    <DropdownItem 
                      onClick={handleCollapseNavbar}
                      to="/upcoming-events" tag={Link} >
                      Registration CPE Programes
                    </DropdownItem>
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/CalenderEvents" tag={Link}
                    >
                      Event Calender
                    </DropdownItem>
                    {/* <DropdownItem divider /> */}
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                     Study Group
                    </DropdownItem> */}
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/Publication-News" tag={Link}
                    >
                      GBN Branch Newsletter
                    </DropdownItem>
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                     Event Archives
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className=" py-2">Initiatives</DropdownToggle>
                  <DropdownMenu
                    aria-labelledby="navbar-primary_dropdown_3"
                    left
                  >
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/articals" tag={Link}
                    >
                      Article Placements
                    </DropdownItem>
                    <DropdownItem
                      onClick={handleCollapseNavbar}
                      to="/networkingZone" tag={Link}
                    >
                      Networking Zone
                    </DropdownItem>
                    
                  </DropdownMenu>
                </UncontrolledDropdown>

                {/* <UncontrolledDropdown  to="/Download-Resources" tag={Link} className="mt-2 text-white text-capitalize">
                  Download
                </UncontrolledDropdown> */}

                    
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  onClick={handleCollapseNavbar}  to="/Download-Resources" tag={Link}  className=" py-2">Download</DropdownToggle>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  onClick={handleCollapseNavbar}  to="/Icai-Cricket-League" tag={Link}  className=" py-2">CIRC Cricket League</DropdownToggle>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav  onClick={handleCollapseNavbar}  to="/ICAI-Registration-From" tag={Link}  className="py-2 blinking-text">Annual Member Registration(2024-25)</DropdownToggle>
                </UncontrolledDropdown>

                {/* <Nav nav onClick={handleCollapseNavbar}  to="/Download-Resources" tag={Link} className="text-black textWhiteDownloadHeader text-capitalize py-2">
                  Download
                </Nav> */}

                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className="py-2">Download</DropdownToggle>
                  <DropdownMenu aria-labelledby="navbar-primary_dropdown_3" left>
                    <DropdownItem onClick={handleCollapseNavbar} to="/Download-Resources" tag={Link} className="dropdown-menu-item">
                      Download Resources
                    </DropdownItem>
                    <DropdownItem onClick={handleCollapseNavbar} to="/" tag={Link} className="dropdown-menu-item">
                      Download Event PPT
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className=" py-2">Study Circle</DropdownToggle>
                  <DropdownMenu
                    aria-labelledby="navbar-primary_dropdown_3"
                    left
                  >
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Student Event Registration
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Event Archives
                    </DropdownItem>
                    
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      ICAI Student Section
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <NavItem className="d-none d-lg-block ml-lg-4"> */}
                </Nav>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                    <Button onClick={handleCollapseNavbar}
                          className="btn btn-sm btn-icon text-white border-0 ml-1 rounded-pill shadow-sm contactBackgroundColour"
                          color="transparent"
                          to="/Contact-Us" tag={Link}
                  >
                  <span className="nav-link-inner--text ml-1 text-white text-capitalize" style={{ fontSize: "larger" }}>
                      Contact Us
                  </span>

                  </Button>

                  {/* </NavItem> */}
              </Nav>
            </UncontrolledCollapse>
          </div>
        </Navbar>
    </>
  );
 
}
export default IcaiNavBar;