import React from "react";
import {
    // Container,
    Row,
    Col,
    // Card,
    // CardImg
  } from "reactstrap";
import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
import ProfileCard from "views/IndexCompnents/ImagesSection/ProfileCard";
// import Promo from "assets/img/theme/img-1-1200x1000.jpg";
import CounterCard from "views/IndexCompnents/CardsSection/ColorCards";
import { imageURL } from "components/URLs/URL";
function Chairman({ChairmanMsg}) {
    console.log(ChairmanMsg,'chairman res');
    return(
        <>
         <section className="section bg-secondary pt-4">
         <h3 className="display-3 pl-6 allMainHeading imageButton chairmanHeading my-3 pb-4" style={{ lineHeight: '1.4rem', }}>Chairman Communication</h3>
         
            {/* <Container> */}
            {ChairmanMsg?.map((ele, index) => 
              <Row className="row-grid justify-content-center align-items-center px-2"  key={index}>
                {/* <Col lg="8" sm="12">
                    <Row> */}
                      <Col lg="8" sm="12">
                        <CounterCard bgColor="default" opacity="2" className="mx-1">
                          <div className="align-items-center mx-3">
                          {/* <i className="fa fa-eye-slash" /> */}
                            <h4 className="text-white pl-1 mb-0">Vision</h4>
                              <p className="mb-0 h6 text-white text-justify p-1">
                                {ele.ourvision.split('\n').map((vision, index) => (
                                  <React.Fragment key={index}>
                                    {vision}
                                    <br /> {/* Render a line break after each part */}
                                  </React.Fragment>
                                ))}
                              </p>
                                {/* Maintaining composure or being calm and patient is the hallmark of a successful professional. 
                                This mind-set is one which creates a reassuring, positive work environment and is the trademark of a true leader..</p> */}
                          </div>
                        </CounterCard>


  
                      {/* </Col> */}
                {/* </Row> */}
                    {/* <Row> */}
                      <div className="mx-1">
                        <p className="lead">
                          Dear Colleagues,
                        </p>
                        <p className="text-justify mb-0">
                          {ele.description.split('<br>').slice(0, 4).map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br /> {/* Render a line break after each part */}
                            </React.Fragment>
                          ))}
                        </p>
                        
                        <ButtonLink href="/chairman-message" className="text-success" >Read More</ButtonLink>
                      </div>

                    {/* </Row> */}

                  </Col>
                    <Col lg="3" sm="8">
                      <ProfileCard alt="abc" src={`${imageURL}/public/img/${ele.images}`} title={ele.name} description={ele.designation ? ele.designation : "Chairman"}>
                      <div className="bg-light w-100 rounded-bottom pl-2">
                        <p className="mb-0 py-1 h6">{ele.address}</p>
                        {/* <Row className="align-items-center">
                          <Col lg="5">
                            <i className="fa fa-phone text-bold" /> {ele.contact_no}
                          </Col>
                          <Col lg="7">
                            <i className="fa fa-paper-plane" /> {ele.email}
                          </Col>
                        </Row> */}
                      </div>
                

                      </ProfileCard>
                    </Col>
                   
              </Row>
             
               )}
            {/* </Container> */}
          </section>
        </>
    );
}

export default Chairman;