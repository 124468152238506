import React, { useEffect, useState } from "react";
import StudyCircle from "components/StudyCircle/GBNStudyCircle";
import FetchApiMethod from "Helpers/FetchApiMethod";

const StudyCircleDetail= () => {
    const [studydata, setStudyCircleData] = useState();

      const fetchStudyCircleData = async () => {
        try {
          // const apiUrl = "https://icaigbnapi.proeffico.com/api/Studycircle?postman=Y";
          // const settings = {
          //   method: "POST",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //     Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          //   },
          // };
          const response = await FetchApiMethod("Studycircle?postman=Y", "POST");
          // const data = await response.json();
          // const fdata =data.data
          // setStudyCircleData(fdata?.map((i) => i));
          setStudyCircleData(response.data || []);
        } catch (error) {
          console.error("Error fetching study circle details:", error);
        }
      };
    
      useEffect(() => {
        fetchStudyCircleData();
      }, []);

      return(
        <>
         <section className="section">

                <h3 className="display-3 text-start chairmanPageCSSMsg allMainHeading">Greater Noida CPE Study Circle</h3>
             <div className="container-fluid justify-content-center chairmanPageCSSMsg">
                    <StudyCircle studyData={studydata}/>
                    
         
            </div>
        </section>
        </>
    );
}

export default StudyCircleDetail;