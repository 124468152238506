import React, { useState, useEffect } from "react";
import "../../../app.css";
import { Button } from "reactstrap";
import eventBanners from "assets/img/theme/EventDetailsBanners.jpeg";
import { FaClock, FaUser, FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { Card, CardImg, CardBody, CardTitle, CardText, Badge, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import Loading from "components/LoadingComponents/Loading.js";
import FormExample from '../UpcomingEvents/MemberRegForm'
import NewCALogo from "assets/img/theme/NewCA-Logo.jpg"
import FetchApiMethod from "Helpers/FetchApiMethod";
import { imageURL } from "components/URLs/URL";

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
// }

function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        day = '31';
    }

    return `${day}-${month}-${year}`;
}



const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [show, setShow] = useState(false);

   const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  // console.log(EventDetails,'line no 17');

  const fetchEvent = async (id) => {
    // const apihandler = `https://icaigbnapi.proeffico.com/api/event/getEvents?postman=Y&event_id=${id}`;
    // const settings = {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization:
    //       "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
    //   },
    // };

    // console.log(apihandler,'line no 30');

    try {
      const apihandlerres = await FetchApiMethod(`event/getEvents?postman=Y&event_id=${id}`, "POST");
      // const respodata = await apihandlerres.json();
      // setEvent(respodata.data[0]);
      setEvent(apihandlerres.data[0] || []);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  useEffect(() => {
    fetchEvent(id);
  }, [id]);

  if (!event) {
  return <Loading />;
}

const handlemodelview = () => {
  show == false ? setShow(true) : setShow(false);
}

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Card>
          {/* <CardImg top width="100%" src={eventBanners} alt="Event Image" className="event-details-page" /> */}
          <Row className="justify-content-center">
            <img alt="Event Details Images" className="imgEventHeight" src={`${imageURL}/public/img/${event.images}`} onError={(e) => e.target.src = NewCALogo} top style={{ height: '35rem', width: '100%' }}/>
          </Row>
          <CardBody style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
            <br /> <br /> <br /> <br /> <br /> <br />
            <div className="text-center mt-6">
              {/* <h1 className="text-center text-white display-1">{event.event_name}</h1> */}
            </div>
          </CardBody>
        </Card>
      </div>

        <div className="container-fluid locatioEventCenter">
        {/* style={{marginTop: '-3rem'}} */}
          <Row className="bg-secondary align-items-center justify-content-center " >
            <Col lg="2" className="bg-light p-2 border-right border-white" style={{ display: 'flex', alignItems: 'center' }}>
              <FaClock size="2em" color="black" />
              <p style={{ margin: '0 5px', fontWeight: 500 }}>0</p>
              <h6 style={{ margin: '0' }}> Days To Go</h6>
            </Col>

            <Col lg="2" className="bg-light p-2  border-right border-white" style={{ display: 'flex', alignItems: 'center' }}>
                <FaCalendarAlt size="2em" color="black" />
                <p style={{ margin: '0 5px', fontWeight: 500 }}>
                    {formatDate(event.start_date)}
                </p>
            </Col>

            <Col lg="6" className="bg-light p-2  border-right border-white" style={{ display: 'flex', alignItems: 'center' }}>
              <FaMapMarkerAlt size="2em" color="black" />
              <p style={{ margin: '0 5px', fontWeight: 500 }}>{event.address}</p>
            </Col>
          </Row>

          <Row className="justify-content-center bg-white">
            <Col lg="8" className="bg-secondary p-2">
                <h2 className="text-center mb-4 allMainHeading">{event.event_name}</h2>
              <h5 className="text-center" style={{ color: "#05007E" }}>
                Last Date: {formatDate(event.end_date)}
              </h5>
              <p className="text-center">
                <b className="text-black">Description:</b> {event.description}
              </p></Col>
          </Row>
        </div>


        <Card className="bg-white pt-0 border-0">
          

          <Row className="justify-content-center mx-3">
            <table className="table table-bordered border-dark">
              <thead>
                <tr>
                  <th>Topic</th>
                  <th>Speakers</th>
                </tr>
              </thead>
              <tbody>
                {event.events_topics.map((topic, index) => (
                  <tr key={index}>
                    <td>{topic.topic_names}</td>
                    <td>{topic.speaker_names}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan="2"><b>Fees: Member {event.member_fee} </b>(Excluding GST)</td>
                </tr>
                <tr>
                  <td colSpan="2"><b>Fees : Non Member {event.non_member_fee} </b>(Excluding GST)</td>
                </tr>

              </tbody>
            </table>
          </Row>

          {/* <div>
            <Button
                  className="btn-neutral btn-icon mb-3 mb-sm-0 ml-1 btn rounded-pill shadow-sm mx-6"
                  color="default"
                  onClick={handlemodelview}
                target="_blank">
                Member Registration
            </Button>

            <Button
                className="btn-neutral btn-icon mb-3 mb-sm-0 ml-1 btn rounded-pill shadow-sm"
                color="default"
                target="_blank"
                  onClick={handlemodelview}
              >Quick Registeration For Non-Members
            </Button>
          </div>
          <br/> */}
        </Card>
        {show == false ? '' : <FormExample />}
    </div>
  );
};

export default EventDetails;