// Layout.js
import React from 'react';
import HeroHeader from "views/IndexCompnents/HeaderSection/HeroHeader";
import IcaiNavBar from "components/Navbars/IcaiNavBar";
import MyFooter from "components/Footers/IcaiFooter";
import WhatsAppIcon from 'components/WhatsAppIcon/WhatsAppIcon';
// import ChatIcon from 'components/AIBoot/ChatIcon';
import ScrollToTop from 'components/GoToTop/ScrollToTop';
import { FaImages, FaCalendarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';  
import { UncontrolledTooltip } from 'reactstrap';  
function WebsiteLayout({ children }) {
  return (
    <>
      <HeroHeader />
      <IcaiNavBar />
      <div className="nav-icons floating bg-white shadow rounded-left border-light border-2">
        <Link to="/Recent-Photos" className="icon-link btn-tooltip" id="tooltipGallery" >
          <FaImages className="icon " />
        </Link>
        
        <Link to="/CalenderEvents" className="icon-link btn-tooltip" id="tooltipEvent">
          <FaCalendarAlt className="icon" />
        </Link>
      </div>

      {/* <ChatIcon /> */}
      <ScrollToTop />
      <WhatsAppIcon />
      <main>{children}</main>
      <MyFooter />
    </>
  );
}

export default WebsiteLayout;