import * as Yup from "yup";

export const networkingZoneSchemaFilter = Yup.object().shape({
  // category: Yup.string().required("Atleast category is required!"),
  // city: Yup.string().required("Firm name is required!"),
  // pincode: Yup.string().min(6, "Pincode must be at least 6 characters").required("Pincode is required"),
});
export const addNetworkZoneSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  category:Yup.string().required('Category is required'),
  location: Yup.string().required("Location is required"),
  position: Yup.string().required("Position is required").matches(/^[A-Z a-z]+$/, "Must be only letters"),
  request: Yup.string().required("Position detail is required"),
  total_vacancy: Yup.string().required("Total vacancy is required").matches(/^[1-9]/,"Must be only numbers"),
  contact_number: Yup.string().required("Contact is required").matches(/^[0-9]+$/, "Must be only digits").min(10, "Must be exactly 10 digits").max(10, "Must be exactly 10 digits"),
});

export const applyPostSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone is required").matches(/^[0-9]+$/, "Must be only digits").min(10, "Must be exactly 10 digits").max(10, "Must be exactly 10 digits"),
});