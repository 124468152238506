import React, { useEffect, useState } from "react";
import DownloadPdf from "components/MaterialDownload/DownloadPdf";
import FetchApiMethod from "Helpers/FetchApiMethod";

const DownloadResources = () => {
  const [materialdata, setDownloadMaterialData] = useState([]);

  const fetchDownloadMaterialData = async () => {
    try {
      const getDownloadMaterial = await FetchApiMethod(
        "membership/getDownloadmaterial?postman=Y",
        "POST"
      );
      setDownloadMaterialData(getDownloadMaterial.data || []);
    } catch (error) {
      console.error("Error fetching download data details:", error);
    }
  };

  useEffect(() => {
    fetchDownloadMaterialData();
  }, []);

  return (
    <section className="section">
      <h3 className="display-3 text-start chairmanPageCSS allMainHeading">Download Resources</h3>
      <div className="container-fluid justify-content-center chairmanPageCSS">
        <DownloadPdf materialData={materialdata} />
      </div>
    </section>
  );
};

export default DownloadResources;
