import { imageURL } from 'components/URLs/URL';
import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'; 
import { 
    CarouselControl, 
    Carousel, 
    CarouselItem, 
    CarouselIndicators, 
} from 'reactstrap'; 

function IcaiCarousel(props) {
  const { banners } = props;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const itemLength = banners?.length - 1;

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const carouselItemData = banners?.map((banner, index) => {
    return (
      <CarouselItem
        key={`${index}_banners`}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
      >
        <img className='bannerHeight' src={`${imageURL}/public/img/${banner.image_url}`} width={"100%"}  style={{height:"30rem"}}/>
      </CarouselItem>
    );
  });

  return (
    // <div style={{ display: 'block', width: 320, padding: 30 }}>
    <div className='divBannerHeight' style={{ display: 'block', width: "100%", height: "30rem",}}>
      <Carousel previous={previousButton} next={nextButton} activeIndex={activeIndex}>
        <CarouselIndicators items={banners} activeIndex={activeIndex} onClickHandler={(newIndex) => { if (animating) return; setActiveIndex(newIndex); }} />
        {carouselItemData}
        <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
        <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} />
      </Carousel>
    </div >
  );
}

export default IcaiCarousel;