import React, { useEffect, useState } from "react";

// reactstrap components
import {Card, Container, Row, Col, CardBody, CardTitle, CardImg, CardText, CardHeader, } from "reactstrap";
import { useLocation } from "react-router-dom";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { imageURL } from "components/URLs/URL";

function PublicationNewsletterDet(){

    const [PubNewsdata, setPubNews] = useState(null);
    const location = useLocation();
    const url_id = new URLSearchParams(location.search).get("id");

      const fetchPubNews = async (url_id) => {
        try {
          // const apiUrl = `https://icaigbnapi.proeffico.com/api/event/getPublicationsnewsletter?postman=Y&id=${url_id}`;
          // const settings = {
          //   method: "POST",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //     Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          //   },
          // };
          const response = await FetchApiMethod(`event/getPublicationsnewsletter?postman=Y&id=${url_id}`,"POST");
          // const data = await response.json();
          // setPubNews(data.data[0] || null);
          setPubNews(response.data[0] || []);
        } catch (error) {
          console.error("Error fetching Publication details:", error);
        }
      };
    
      useEffect(() => {
        fetchPubNews(url_id);
      }, [url_id]);
    
      return (
        <>
            <section className="section">
                <Container>
                    <Card className="card-profile shadow mt--500">
                    <CardHeader><h3 class="ml-3 mb-0">Publication Newsletter Detail</h3></CardHeader>
                    <CardBody className="py-3" style={{minHeight:"450px"}}>
                    <Row className="justify-content-between align-items-center">

                        <Col lg="8" sm="8">
                            <CardTitle className="text-bold text-dark mt-3 text-uppercase" style={{ color: '#212125' }}>{PubNewsdata?.title}</CardTitle>
                        </Col>
                        <Col lg="2" sm="2">
                        <CardText className="description text-dark text-left">Pub. At: {PubNewsdata?.publication_date}</CardText>
                            {/* <Badge  color="success" pill className="mr-1"></Badge>       */}
                        </Col>
                        </Row>
                        <Row className="justify-content-between align-items-baseline">
                        {PubNewsdata?.document_url && ( <Col>
                          <a href={`javascript:void(window.open('${PubNewsdata.document_url}', '_blank'))`}><span className="h6 text-primary">View Document</span></a>
                          </Col>
                          )}
                          {PubNewsdata?.pdf_name && ( <Col lg="1" sm="1" className="text-center donloadDataLeftAlign">
                                <a href={`${imageURL}/public/img/${PubNewsdata.pdf_name}`} download>
                                    <i class="fa fa-download text-default fa-2x iconBgDonload" aria-hidden="true"  style={{ cursor: 'pointer' }}></i>
                                </a>
                            </Col>
                            )}
                        </Row>
                        <CardText className="description mt-0">{PubNewsdata?.description}</CardText>
                       
                        <Row className="justify-content-between">
                            <Col>
                                <CardImg alt="Notice Image" src={`${imageURL}/public/img/${PubNewsdata?.thumbnail}`}  bottom style={{ height: '15rem', width: '15rem'}}/>      
                            </Col>    
                           
                        </Row>

                    </CardBody>
                    </Card>
                </Container>
            </section>
        </>
    );
}
export default PublicationNewsletterDet;