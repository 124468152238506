import { imageURL } from "components/URLs/URL";
import React from "react";
import { Card, Row ,Col } from "reactstrap";



const DownloadPdf = (props) => {
const materialData= props.materialData;
    return (
        <>
   {materialData?.map((ele,index)=>
            <Card className="px-0 rounded shadow-sm mb-3 d-flex justify-content-start donloadDataPadding" key={`${index}_data`}>
                <Row className="m-0">
                    <Col lg="1" sm="1" className="bg-secondary p-2  text-center donloadDataLeftAlign">
                        <i className="fa fa-file-text iconBgDonload" />   
                    </Col>
                    <Col  lg="10" sm="2" className="p-2">
                        <h6 className="m-0 text-truncate">{ele.title}</h6>
                    </Col>
                    {/* <Col lg="8 " sm="8" className="p-2">
                        <p className="m-0 text-truncate">{ele.description}</p>
                    </Col> */}
                    {/* <Col lg="1" sm="1" className="p-2  text-center donloadDataLeftAlign">
                        <a href={`https://icaigbnapi.proeffico.com/public/img/${ele.doc_url}`} download>
                            <i class="fa fa-download text-default iconBgDonload" aria-hidden="true"  style={{ cursor: 'pointer' }}></i>
                        </a>
                    </Col> */}
                    <Col lg="1" sm="1" className="p-2 text-center donloadDataLeftAlign">
                    <a href={`javascript:void(window.open('${imageURL}/public/img/${ele.doc_url}', '_blank'))`}>
                    <i class="fa fa-download text-default iconBgDonload" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                    </a>
                    </Col>
                </Row>
            </Card>
   )}
        </>
    );
}
export default DownloadPdf;